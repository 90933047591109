import React from 'react';
import '../components/layout.scss';
import Layout from '../components/layout';
import * as styles from './grand-format.module.scss';
import SEO from '../components/seo';
import rollup from '../images/produits/grand-format/rollup.jpg';
import plv from '../images/produits/grand-format/plv.jpg';
import panneau from '../images/produits/grand-format/panneau.jpg';
import bache from '../images/produits/grand-format/bache.jpg';
import adhesif from '../images/produits/grand-format/adhesif.jpg';
import affiche from '../images/produits/grand-format/affiche.jpg';
import decoration from '../images/produits/grand-format/decoration.jpg';
import evenementiel from '../images/produits/grand-format/evenementiel.jpg';
import { Link } from 'gatsby';

const GrandFormatPage = () => (
  <Layout>
    <SEO title="Produits Grand Format" />

    <section>
      <div className="large-container">
        <div className="row">
          <div className="col-sm-12 text-center">
            <h1 className="static-h1">Le Grand Format</h1>
            <p>
              Voyez grand avec des supports XXL, afin que votre communication ne
              passe pas inaperçue. Vous êtes revendeur ? Découvrez notre{' '}
              <Link className="primary" to="/revendeurs">
                Solution spéciale Revendeurs
              </Link>
            </p>
            <div className={styles.categories}>
              <div>
                <button
                  onClick={() => animateScroll('#rollup')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>Roll-ups et banners</strong>
                </button>
                <button
                  onClick={() => animateScroll('#plv')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>PLV</strong>
                </button>
                <button
                  onClick={() => animateScroll('#panneau')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>panneaux rigides</strong>
                </button>
                <button
                  onClick={() => animateScroll('#bache')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>bâches et banderoles</strong>
                </button>
                <button
                  onClick={() => animateScroll('#adhesif')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>adhésif</strong>
                </button>
                <button
                  onClick={() => animateScroll('#affiche')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>affiches papier</strong>
                </button>
              </div>
              <div>
                <button
                  onClick={() => animateScroll('#decoration')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>décoration</strong>
                </button>
                <button
                  onClick={() => animateScroll('#evenementiel')}
                  className={`${styles.btn} ${styles.btnSecondary}`}
                >
                  <strong>événementiel, salons et produits d'exposition</strong>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="large-container">
        <div className={styles.produits}>
          <div className="card" id="rollup">
            <div className={styles.produit}>
              <div>
                <img src={rollup} />
              </div>
              <div>
                <h2>Roll-ups et banners</h2>
                <ul>
                  <li>Roll-ups d’intérieur et outdoor</li>
                  <li>X-banners</li>
                  <li>L-banners</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card" id="plv">
            <div className={styles.produit}>
              <div>
                <img src={plv} />
              </div>
              <div>
                <h2>PLV</h2>
                <ul>
                  <li>Kakémonos (PLV suspendue)</li>
                  <li>Totems d’intérieur et outdoor</li>
                  <li>Photocall (mur d’images) en bâche PVC ou textile</li>
                  <li>Beach flag (oriflammes)</li>
                  <li>Stop trottoirs</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card" id="panneau">
            <div className={styles.produit}>
              <div>
                <img src={panneau} />
              </div>
              <div>
                <h2>Panneaux rigides</h2>
                <ul>
                  <li>Sur-mesure</li>
                  <li>PVC, Akilux, aluminium, Dibond, Plexiglass, carton</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card" id="bache">
            <div className={styles.produit}>
              <div>
                <img src={bache} />
              </div>
              <div>
                <h2>Bâches et banderoles</h2>
                <ul>
                  <li>PVC, textile, backlight, indéchirables</li>
                  <li>Sur-mesure, recto-verso, micro-perforées (grand vent)</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card" id="adhesif">
            <div className={styles.produit}>
              <div>
                <img src={adhesif} />
              </div>
              <div>
                <h2>Adhésif</h2>
                <ul>
                  <li>Standard, vitrophanie, magnétique</li>
                  <li>Pour le sol, micro-perforé, pour véhicules</li>
                  <li>Autocollants / Stickers</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card" id="affiche">
            <div className={styles.produit}>
              <div>
                <img src={affiche} />
              </div>
              <div>
                <h2>Affiches papier</h2>
                <ul>
                  <li>Grand format sur mesure</li>
                  <li>Abribus</li>
                  <li>4, 8 et 12 m²</li>
                  <li>Backlight sur mesure</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card" id="decoration">
            <div className={styles.produit}>
              <div>
                <img src={decoration} />
              </div>
              <div>
                <h2>Décoration</h2>
                <ul>
                  <li>Papiers peints</li>
                  <li>Posters, tableaux</li>
                  <li>Mur toile tendue</li>
                  <li>Lino pour le sol</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="card" id="evenementiel">
            <div className={`${styles.produit} ${styles.produitEvenementiel}`}>
              <div>
                <img src={evenementiel} />
              </div>
              <div>
                <h2>Évènementiel, salons & produits d'exposition</h2>
                <ul>
                  <li>Stands classiques et parapluie, en tissu et PVC</li>
                  <li>Tentes</li>
                  <li>Enseignes suspendues</li>
                  <li>
                    Comptoirs et produits complémentaires : présentoirs,
                    chevalets, minis roll-ups, flyers
                  </li>
                  <li>Cadres textile tendu</li>
                  <li>Drapeaux publicitaires</li>
                  <li>Signalétique</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);

export default GrandFormatPage;

function animateScroll(targetSelector) {
  const targetPosition = getElementOffsetTop(
    document.querySelector(targetSelector)
  );

  console.log('targetPosition', targetPosition);

  const initialPosition = window.scrollY;
  const SCROLL_DURATION = 30;
  const maxDistance = document.body.scrollHeight - window.innerHeight;

  const scrollDistance =
    maxDistance > targetPosition
      ? targetPosition - initialPosition
      : maxDistance;
  const stepX = Math.PI / SCROLL_DURATION;
  let stepCount = 0;
  if (window.requestAnimationFrame) {
    requestAnimationFrame(step);
  }

  function step() {
    if (stepCount < SCROLL_DURATION) {
      requestAnimationFrame(step);
      stepCount++;
      window.scrollTo(
        0,
        initialPosition +
          scrollDistance * 0.25 * Math.pow(1 - Math.cos(stepX * stepCount), 2)
      );
    }
  }
}

const getElementOffsetTop = (element, currentOffset = 0) => {
  console.log('element', element);

  if (element.offsetParent) {
    return getElementOffsetTop(
      element.offsetParent,
      currentOffset + element.offsetTop
    );
  }

  return currentOffset;
};
